
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import CModal from "@/components/Modal.vue";

import { formatDate, addMoneyMask } from "@/libs/utils";

const VBatchList = defineComponent({
  name: "VBatchList",
  components: { PDataTable, PColumn, CModal },
  setup() {
    useHead({ title: "Lista de guias do lote de pagamento | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const batch = computed(() => store.state.batch.current);
    const loading = reactive({ list: false });

    const breadcrumb = [
      {
        label: "Lotes",
        icon: "fas fa-boxes-packing mr-1",
        to: { name: "batch-details", query: route.query },
      },
    ];

    function handleClose() {
      router.replace({ name: "batch-list" });
    }

    async function getBatch() {
      loading.list = true;
      await store.dispatch("getBatch", { _id: route.params._id.toString() });
      loading.list = false;
    }

    getBatch();

    return {
      ...{ batch, loading, breadcrumb },
      ...{ getBatch, handleClose },
      ...{ formatDate, addMoneyMask },
    };
  },
});

export default VBatchList;
